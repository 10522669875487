import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  get togglers() {
    return this.context.bindingObserver.bindings
        .filter((binding) => binding.action.methodName == "toggle")
        .map((binding) => binding.action.element);
  }

  get openedTogglers() {
    return this.togglers.filter((toggler) => this.isOpened(toggler));
  }

  get contents() {
    return this.scope.findAllElements("[data-accordion-id]");
  }

  connect() {
    this.init();
  }

  init() {
    this.togglers.forEach((toggler, index) => {
      let content = this.findContent(toggler);
      // if (index === 0 && toggler.dataset.open === "true") {
      //   this.show(toggler, content, false);
      //   toggler.setAttribute('aria-expanded', 'true');
      // } else {
      //   this.hide(toggler, content, false);
      //   toggler.setAttribute('aria-expanded', 'false');
      // }
    });
  }

  toggle(e) {
    this.togglers.forEach((toggler) => {
      if (toggler.contains(e.target)) {
        if (this.isOpened(toggler)) {
          this.close(toggler);
        } else {
          this.open(toggler);
        }
      } else if (this.isOpened(toggler)) {
        this.close(toggler);
      }
    });

    e.preventDefault();
  }

  open(toggler) {
    let content = this.findContent(toggler);
    this.show(toggler, content);
    toggler.setAttribute('aria-expanded', 'true');
    this.dispatch("opened", { detail: { toggler: toggler, content: content } });
  }

  close(toggler) {
    let content = this.findContent(toggler);
    this.hide(toggler, content);
    toggler.setAttribute('aria-expanded', 'false');
    this.dispatch("closed", { detail: { toggler: toggler, content: content } });
  }

  show(toggler, content, transition = true) {
    if (transition) {
      content.style.height = "auto";
      content.style.opacity = "1";  // Set opacity to 1 to make it visible
      content.style.visibility = "visible";  // Ensure visibility is set to visible
      content.style.overflow = "visible";
    }
    this.toggleClass(toggler, content, true);
  }

  hide(toggler, content, transition = true) {
    if (transition) {
      content.style.height = "0px";
      content.style.opacity = "0";  // Set opacity to 0 to hide it
      content.style.visibility = "hidden";  // Ensure visibility is set to hidden
      content.style.overflow = "hidden";
    }
    this.toggleClass(toggler, content, false);
  }

  transitionEnd(e) {
    e.target.style.height = "";
  }

  toggleClass(toggler, content, opened) {
    if (opened) {
      toggler.classList.add("st-accordion__icon--opened");
      toggler.dataset.state = "open";
    } else {
      toggler.classList.remove("st-accordion__icon--opened");
      toggler.dataset.state = "closed";
    }
  }

  isOpened(toggler) {
    return toggler.getAttribute('aria-expanded') === 'true';
  }

  findContent(toggler) {
    let id = this.getID(toggler);
    return this.scope.findElement(`[data-accordion-id="${id}"]`);
  }

  getID(toggler) {
    return toggler.getAttribute("href").replace(/^#/, "");
  }
}
